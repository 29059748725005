
<template>
  <div class="py-5 mb-5" id="back">
    <div class="d-flex align-items-center justify-content-center" style="height:250px;" v-if="load">
      <loader color="#e0a638" size="100" />
    </div>
    <div class="col-md-12 p-0 ml-auto mb-4 row d-flex align-items-center justify-content-between" id="eyeButton">
    <div id="allButtonsYear">
      <div class="d-flex" >
        <button :class="`btn_default` " @click="changeDre(item.year)" :value="item.year" v-for="item in year" :key="item.year" >{{ item.year }}</button>
      </div>
        
        <!-- <option :value="null">Selecione o ano</option> -->
      <!-- <select class="selectYear" v-model="currentYear">
        <option :value="item.year" v-for="item in year" :key="item.year">{{ item.year }}</option>
      </select> -->
    </div>
      <div id="allButtonsYear">
        <vue-json-to-csv
          v-if="objDre.dre !=''"
          :json-data="objDre.dre"
          :labels="{ month: { title: 'Mês' }, grossProfit: { title: 'Lucro Bruto' }, expense: { title: 'Despesa' },netProfit:{ title: 'Receita Liquida'} }"
          :csv-title="'objDre'"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)"
        >
          <button id="buttonDRE" class="btn_default">Exportar DRE</button>
        </vue-json-to-csv>
      </div>
    </div>

    <div class="col-md-12" id="FluxoContainer">
      <p class="fluxoP"><strong>Fluxo de Caixa</strong></p>
      <div class="row">      
        <transition name="vertical" v-if="this.sizeWindow > 767">
          <caixa :dataField="dataField" @showModalDetails="openModal" class="col-md-3" v-show="!load" :item="objDre.dre" :year="objDre.year"/>
        </transition>
        <transition name="vertical" v-if="this.sizeWindow > 767">
          <div
            class="col-md-9 element_div px-0 elementDiv"
            v-show="!load"
          >
            <div class="d-flex over-scroll" id="scroll">
              <fluxo @showModalDetails="openModal" class="itens" v-for="(item ,index) in objDre.dre" :key="index" :result="item" :year="objDre.year" />
            </div>
          </div>
        </transition>
        <div v-if="this.sizeWindow <= 767" class="allInfoDreMobile">
          <div class="infoAboutSingleCont">
            <div v-if="this.sizeWindow <= 767"><lucroBrutoInfo @showModalDetails="openModal" :year="objDre.year" :dataField="dataField" v-show="!load" :item="objDre.dre"></lucroBrutoInfo></div>
            <div class="lucroBrutoValueCont" v-if="this.sizeWindow <= 767"><lucroBrutoValue @showModalDetails="openModal" v-for="(item ,index) in objDre.dre" :key="index" :result="item" :year="objDre.year" :dataField="dataField" v-show="!load" :item="objDre.dre"></lucroBrutoValue></div>
          </div>
          <div class="infoAboutSingleCont">
            <div v-if="this.sizeWindow <= 767"><despesasInfoVue @showModalDetails="openModal" :year="objDre.year" :dataField="dataField" v-show="!load" :item="objDre.dre"></despesasInfoVue></div>
            <div class="lucroBrutoValueCont" v-if="this.sizeWindow <= 767"><despesasValueVue @showModalDetails="openModal" v-for="(item ,index) in objDre.dre" :key="index" :result="item" :year="objDre.year" :dataField="dataField" v-show="!load" :item="objDre.dre"></despesasValueVue></div>
          </div>
          <div class="infoAboutSingleCont">
            <div v-if="this.sizeWindow <= 767"><lucroLiquidoInfoVue @showModalDetails="openModal" :year="objDre.year" :dataField="dataField" v-show="!load" :item="objDre.dre"></lucroLiquidoInfoVue></div>
            <div class="lucroBrutoValueCont" v-if="this.sizeWindow <= 767"><lucroLiquidoValueVue @showModalDetails="openModal" v-for="(item ,index) in objDre.dre" :key="index" :result="item" :year="objDre.year" :dataField="dataField" v-show="!load" :item="objDre.dre"></lucroLiquidoValueVue></div>
          </div>
        </div>
        <modaldre :propsModal="dataField" :dataRE="dataRE" :itemDre="itemDre" @closeModal="closeModal"></modaldre>
      </div>
    </div>
   
  </div>
</template>

<script>
import lucroLiquidoInfoVue from "./dre/dreMobile/lucroLiquidoInfoMobile.vue"
import lucroLiquidoValueVue from "./dre/dreMobile/lucroLiquidoValueMobile.vue"
import despesasInfoVue from "./dre/dreMobile/despesasInfoMobile.vue";
import despesasValueVue from "./dre/dreMobile/despesasValueMobile.vue";
import lucroBrutoInfo from "./dre/dreMobile/lucroBrutoInfoMobile.vue";
import lucroBrutoValue from "./dre/dreMobile/lucroBrutoValueMobile.vue";
import VueJsonToCsv from "vue-json-to-csv";
import fluxo from "./dre/fluxoCaixa";
import caixa from "./dre/caixaIndicador";
import loader from "@/components/utils/_Load";
import HTTP from "@/api_system";
import modaldre from './dre/modal_dre.vue';
export default {
  components: {
    fluxo,
    caixa,
    VueJsonToCsv,
    loader,
    modaldre,
    lucroBrutoValue,
    lucroBrutoInfo,
    despesasInfoVue,
    despesasValueVue,
    lucroLiquidoInfoVue,
    lucroLiquidoValueVue
  },
  data() {
    return {
      objDre: [],
      load: false,
      year: [],
      data: [],
      currentYear: null,
      dataField: {},
      itemDre: [],
      dataRE: [],
      sizeWindow: 0,

    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.getDre();
  },
  methods: {
    closeModal(){
      $('#modal_dre').modal('hide');
    },
    getDre() {
      this.load = true;
      HTTP.get("services/app/Indicators/GetDre", {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.load = false;
          
          let { years, dres } = response.data.result
          this.data = dres;
          this.year = years

          let data = new Date().getFullYear()
          // this.currentYear = data
          this.changeDre(data)

          this.$nextTick(() => {
            // this.scroll();
          });
        })
        .catch(error => {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    },
    changeDre(date) {
      this.data.forEach((item) => {
        if(item.yearCurrent === date) {
          this.objDre = {dre: item.months, year: date} 
          this.dataRE = this.objDre.dre
        }
      })

    },
    scroll() {
      var elementW = $(".element_div").width();
      var w = $(".itens").width() * $(".itens").length;
      var valMin = elementW - w - 30 * $(".itens").length;

      Draggable.create(".over-scroll", {
        type: "x",
        throwProps: true,
        edgeResistance: 0.9,
        maxDuration: 0,
        bounds: { maxX: 0, minX: $(".itens").length > 3 ? valMin : 0 },
        snap: {
          x: [0, valMin]
        }
      });
    },
    handleSuccess(val) {
      this.$notify({
        group: "erros",
        type: "sucess",
        text: `<i class="icon ion-close-circled"></i>DRE Exportado`
      });
    },
    handleError(val) {
      this.$notify({
        group: "erros",
        type: "error",
        text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
      });
    },
    openModal(title, month, year, value, id, img) {
        this.dataField = {
          title: title,
          month: month,
          year: year,
          value: value,
          img: img,
          id: id
        }
        this.changeStyle();
        this.getItens(id, year)
        $('#modal_dre').modal('show');
      },
      getItens(id, year){
        let getIndicator = `/services/app/IndicatorsDetails/GetAll`;
        if(id){
          getIndicator += `?indicatorId=${id}`
        }else{
          getIndicator += `?year=${year}`
        }
        HTTP.get(getIndicator,
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getterToken}`,
						},
					}
				).then((response) => {
          this.itemDre = response.data.result
          }).catch(err => console.log(err))
        },
      changeStyle(){
            if(this.dataField.title === "Receita Bruta"){
                this.rowContainerTotal = document.getElementById('rowContainerTotal');
                this.rowContainerTotal.style.backgroundColor = '#54eb9b';
            }
            else if(this.dataField.title === "Despesa"){
                this.rowContainerTotal = document.getElementById('rowContainerTotal');
                this.rowContainerTotal.style.backgroundColor = '#f3b3b3';
            }else if(this.dataField.title === "Lucro Líquido"){
                this.rowContainerTotal = document.getElementById('rowContainerTotal');
                this.rowContainerTotal.style.backgroundColor = '#a3cbeb';
            }
        },
  },
 
  watch: {
    currentYear: function(value) {
        this.changeDre(value)
    }
  }
};
</script>

<style scoped>

.btn_default {
  margin: 10px 10px 10px 0;
  min-width: 60px;
  height: 40px;
  border: none;
  background-color: #3785c5 !important;
}
.btn_default:hover {
  border: 0.5px #3785c5 solid !important;
  background-color: #10466f!important;
  transition: 0.5s;
}
.btn_default:active{
  background-color: #10466f!important;
}

/* .over-scroll {
  cursor: pointer !important;
} */

.selectYear {
    min-width: 100px;
    height: 40px;
    padding: 10px;
    background: #2474b2;
    color: #fff;
    outline: none;
    border: 2px solid #e0a638;
    -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
    box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
    /* cursor: pointer; */
}

.elementDiv {
  overflow: auto;
  cursor: pointer !important;
  margin-left: 250px;
}

.elementDiv::-webkit-scrollbar {
  width: 1px !important;
  margin-left: 250px;
}

.elementDiv::-webkit-scrollbar-track {
  background: #b8dbef !important;
  height: 0.5px;
  cursor: pointer !important; 
}
.elementDiv::-webkit-scrollbar-thumb {
  background-color: #5db4e6 !important;
  border-radius: 20px !important;
  margin-left: 250px;
  /* border: 3px solid orange !important; */
  
} 

.activeButton {
  background-color: #f38235 !important;
}

#buttonDRE{
  background-color: #f38235 !important;
  width: 150px!important;
}
#buttonDRE:hover{ 
  background-color: #f9ab02 !important;
  border: none!important;
}
#eyeButton{
  margin-left: 0px!important;
  display: flex;
  width: 100%!important;
  margin-bottom: 0px!important;
}
.fluxoP{
  margin-bottom: 0px;
  padding-top: 20px;
  font-size: 25px;
  color: #FFFF;
}
#FluxoContainer{
  padding-left: 0!important;
}
#back{
  width: 100%!important;
}
@media screen and (max-width:767px){
  #allButtonsYear{
    width: 100%;
  }
  #buttonDRE{
    width: 100%!important;
  }
  .allInfoDreMobile{
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .infoAboutSingleCont{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 8px;
  }
  .lucroBrutoValueCont{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    overflow: auto;
  }
  #FluxoContainer{
    padding-left: 15px!important;
  }

}
</style>