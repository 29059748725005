<template>
  <div class="rowFixed">
      <div
      class="allRowContainer"
      >
      <div class="row align-items-center" id="rowContainerGross" style="height: 45px; ">
              <div class="col-md-4" id="linha-item">
                  <p class="numeroPedidoFix mb-0"> <strong>{{"Receita Bruta"}}</strong></p>
              </div>
              <div class="col-md-4" id="linha-item">
                  <p v-if="sizeWindow<= 767" class="numeroPedidoFix mb-0">Valor Total</p>
                  <p class="precoPedidoFix mb-0"><strong>{{ getGross }}</strong></p>
                  <p></p>
              </div>
              <div v-if="sizeWindow > 767" class="col-md-4" id="linha-item">
                  <p class="precoPedidoFix mb-0"><strong>{{ getGross }}</strong></p>
                  <p></p>
              </div>
          </div>

          <div v-for="valueRE in dataRE" :key="valueRE.id" class="row align-items-center" id="rowContainer" style="height: 45px; ">
              <div class="col-md-4" id="linha-item">
                  <p class="numeroPedido mb-0">{{valueRE.month}}</p>
              </div>
              <div class="col-md-4" id="linha-item">
                  <p v-if="sizeWindow <= 767" class="numeroPedidoFix mb-0">Valor Total</p>
                  <p  v-if="valueDEF !== 'Despesas'" class="precoPedido mb-0">{{ valueRE.grossProfit }}</p>
                  <p></p>
              </div>
              <div v-if="sizeWindow > 767" class="col-md-4" id="linha-item">
                  <p  v-if="valueDEF !== 'Despesas'" class="precoPedido mb-0">{{ valueRE.grossProfit }}</p>
                  <p></p>
              </div>
          </div>
          <div class="row align-items-center" id="rowContainerEx" style="height: 45px; ">
              <div class="col-md-4" id="linha-item">
                  <p class="numeroPedidoFix mb-0"><strong>{{"Despesa"}}</strong></p>
              </div>
              <div class="col-md-4" id="linha-item">
                  <p v-if="this.sizeWindow <= 767" class="numeroPedidoFix mb-0">Valor Total</p>
                  <p class="precoPedidoFix mb-0"><strong>{{ getExpense }}</strong></p>
              </div>
              <div v-if="sizeWindow > 767" class="col-md-4" id="linha-item">
                  <p v-if="this.sizeWindow <= 767" class="numeroPedidoFix mb-0">Valor Efetivo</p>
                  <p class="precoPedidoFix mb-0"><strong>{{ getExpense }}</strong></p>
                  <p></p>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  props:["valueDEF", "dataRE"],
  data(){
    return{
      sizeWindow: 0,
    }
  },
  computed: {
    getGross(){
      var somado = 0;
      this.dataRE.forEach( x => {
      somado += parseFloat(x.grossProfit.replace("R$", "").replace(".","").replace(",", "."));
    })
    return `R$ ${this.valueConvertBR(somado)}`;
  },
  getExpense(){
      var somado = 0;
      this.dataRE.forEach( x => {
      somado += parseFloat(x.expense.replace("R$", "").replace(".","").replace(",", "."));
    })
    return `R$ ${this.valueConvertBR(somado)}`;
  }
  },
  mounted(){
    this.sizeWindow = $(window).width();
  }
};

</script>

<style scoped>

.rowFixed {
  max-height: 399px;
  overflow: auto;
}

#rowContainer{
  background-color: #FFFF;
  border-radius: 7px;
  border: 1px #d4d6d8 solid;
  margin-bottom: 3px;
  margin-left: 0px;
  margin-right: 0px;
}
#rowContainerGross{
  background-color: rgb(191, 245, 195);
  border-radius: 7px;
  margin-bottom: 3px;
  margin-left: 0px;
  margin-right: 0px;
}
#rowContainerEx{
  background-color: rgb(250, 210, 210);
  border-radius: 7px;
  margin-bottom: 3px;
  margin-left: 0px;
  margin-right: 0px;
}
#linha-item{
  height: 40%;
  display: flex;
  align-items: center;
}

.numeroPedido {
  font-size: 16px;
  font-weight: 400;
  color: #357eb8;
}
.allRowContainer{
  width: 100%;
  max-height: 285px;

  padding-right: 7px;
}
.precoPedido {
  font-size: 16px;
  font-weight: 400;
  color: #357eb8;
  padding-left: 50%;
}
.precoPedidoFix {
  font-size: 16px;
  font-weight: 400;
  color: black;
  padding-left: 50%;
}
#precoServico{
  font-size: 16px;
  font-weight: 400;
}
.col-md-8{
    padding-left: 51%;
}
.numeroPedidoFix {
  font-size: 16px;
  font-weight: 400;
  color: black;
}
@media screen and (max-width:767px){
  #rowContainerGross{
    height: 50px!important;
  }
  #linha-item{
    height: 44%!important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .precoPedidoFix{
    padding-left: 0!important;
    min-width: 50%;
    max-width: 50%;
  }

  #rowContainer{
    height: 70px!important;
  }
  #rowContainerEx{
    height: 70px!important;
  }
  .numeroPedidoFix{
    min-width: 50%;
    max-width: 50%;
  }
  .precoPedido{
    padding-left: 0!important;
  }
}
</style>

