<template>
	<div class="col-md-4 mb-3">
		<div class="box mx-0">
			<div class="row">
				<div class="col-md-12">
					<div
						class="top_box text-center d-flex align-items-center"
						:class="item.total > 0 ?'positive_bg' : 'negative_bg'"
					>	
					<div :class="item.total > 0 ?'' : 'negativeCircle'" ></div>
						<h4 class="indicador_h4 mb-0">{{item.name}}</h4>
					</div>

					<div class="body_box text-center d-flex align-items-center justify-content-center">
						<h4
							class="valor_h4 mb-0"
							:class="item.total > 0 ?'positive' : 'negative'"
						>
							<strong class="valueReal" v-if="item.name !== 'Margem Líquida'">R$</strong>
							<br>
						 {{ item.name == 'Margem Líquida'? around(item.total) +'%': $options.filters.currency(item.total).replace('R$', '')}}</h4>
					</div>

					<div class="bottom_box col-md-12">
						<div class="row">
							<div
								class="col-md-6 d-flex flex-column justify-content-center"
								v-for="(itens ,index) in  $options.filters.invert(item.meta)"
								:key="index"
								:class="` ${getColor(index)}`"
							>
								<p class="mb-0 metas_p py-1">{{itens.name}}</p> 
								<p
									class="color mb-0"
									:class="itens.diferenca > 0 ? `positive_green` :  `negative`"
								>
								<!-- {{around(itens.porcentagem) > 0 ? around(itens.porcentagem) : 0 }}% -->
								{{aroundMathFixed(itens.porcentagem)}}%  
								</p>
								<p
									class="color mb-0"
									:class="itens.diferenca > 0 ? `positive_green` :  `negative`"
								>{{itens.diferenca | currency}}</p> 
							</div>
							<div
								v-if="item.lastName"
								:class="item.percentageAboutLast > 0 ? `positive_green_bg` :  `negative_bgFooter`"
								class="mediaDia w-100 align-items-center d-flex justify-content-center"
							>
								<p v-if='around(item.percentageAboutLast) >= 0' class="mb-0"> VS. {{item.lastName}} + <strong> {{around(item.percentageAboutLast)+'%'}}</strong></p>
								<p v-else class="mb-0"> VS. {{item.lastName}} {{around(item.percentageAboutLast)+'%'}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["item"],
		data() {
			return {};
		},
		mounted() {},
		watch: {},
		methods: {
			getColor(index) {
				if (index == 0) {
					return "alinhamento_left";
				} else {
					return "alinhamento_right";
				}
			}
		}
	};
</script>

<style scoped>
.box {
	/* box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3); */
	height: 100%;
	border-radius: 12px !important;
}

.top_box {
	height: 40px;
	/* box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3); */
}
.valor_h4 {
	font-style: normal;
	font-weight: 700;
	font-size: 38px;
	text-align: center;
	color: #2474B2;
	padding-bottom: 10px;
}
.positive {
	color: #175d93;
}
.negative {
	color: #F65757;
}
.negativeCircle {
	height: 15px;
	width: 15px;
	background: #F65757;
	border-radius: 50%;
	margin-right: 10px;
}
.positive_green {
	color: #02C64B !important;
}
.positive_green_bg {
	background: #bef2d1;
}
.positive_green_bg p{
	color: #797984;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
}

.alinhamento_left {
	text-align: left;
	height: 85px;
	background-color: #ffffff;
}
.alinhamento_right {
	height: 85px;
	text-align: right;
	background-color: #ffffff;
}
.positive_bg, 
.negative_bg {
	width: 90%;
	background-color: #ffffff;
	border-bottom: 1px solid #98C4E6;
	margin: 0 auto;
}
.negative_bgFooter { 
	background-color: #F9BDC8;
}

.negative_bgFooter p {
	color: #F65757;
} 

.mediaDia {
	color: #ffff;
	height: 30px;
	font-weight: 700;
	width: 90% !important;
    margin: 9px auto;
    border-radius: 5px;
    padding: 20px;
}
.body_box {
	/* height: 80px; */
	border-bottom: 1px solid #E9E9EB;
	width: 90%;
	margin: 0 auto;
}
.color {
	font-size: 12px;
	font-weight: 800;
}
.box {
	width: 100%;
	border-radius: 20px;
	overflow: hidden;
	border: 2px solid #ffffff;
	background-color: #ffffff;
}

.metas_p {
	color: #2474B2;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	text-transform: capitalize;
}
.indicador_h4 {
	font-weight: 400;
	font-size: 16px;
	color: #2474B2;
	
}

.valueReal {
	font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #98C4E6;
  opacity: 0.5;
}
@media screen and (max-width:767px){
	.alinhamento_left {
		width: 50%;
	}
	.alinhamento_right {
		width: 50%;
	}
}
</style>
