<template>
  <modal
    :modalId="`modal_dre`"
    :modalTitle="`${propsModal.title}`"
    :load="false"
    :typeLg="'modal_lg'"
    @closeModal="closeModal"
    :icon="propsModal.img"
    :subTitle="`${propsModal.month} ${propsModal.year}`" 
  >
    <div class="paHead" v-if="this.sizeWindow > 767">
      <p v-if="propsModal.title !== 'Lucro Líquido' && propsModal.month === 'Total'" class="categoria">
        Mês
      </p>
      <p v-if="propsModal.title !== 'Lucro Líquido' && propsModal.month !== 'Total'" class="categoria">
        Categoria
      </p>
      <p v-if="propsModal.month === 'Total' && propsModal.title === 'Lucro Líquido'" class="valor" id="valorTotal">
        Valor Total
      </p>
      <p v-if="propsModal.month === 'Total' && propsModal.title === 'Lucro Líquido'" class="valor" id="valorEfetivo">
        Valor Efetivo
      </p>
      <p v-if="propsModal.title !== 'Lucro Líquido' " class="valor" id="valorValor">
        Valor
      </p>
    </div>
      <div class="modalBody">
        <div class="fixedMounted" v-if="propsModal.title === 'Lucro Líquido' && propsModal.month === 'Total'"><totalLucroLiquido :dataRE="dataRE" :valueDEF="valueDEF" ></totalLucroLiquido></div>
        <div class="fixedMounted" v-if = "propsModal.month !== 'Total' && propsModal.title ==='Lucro Líquido'"><itemLucro :valueMes="valueMes" :valueRE="valueRE" v-for="valueRE in dataRE" :key="valueRE.id"/></div>
        <div class="fixedMounted" v-if="propsModal.month === 'Total' && propsModal.title === 'Receita Bruta' "><totalDEF :valueDEF="valueDEF"  :valueRE="valueRE" v-for="valueRE in dataRE" :key="valueRE.id"/></div>
        <itens-dre class="fixedMounted" :valueDEF="valueDEF" v-show = "propsModal.month !== 'Total' && propsModal.title ==='Receita Bruta'" :propsIndicatorId="`${propsModal.id}`" :item="item" v-for="item in itemDre" :key="item.id"/>

        <total-dre 
          class="totalDre"
          :totalTitle="`${propsModal.title}`"
          :totalPrice="`${propsModal.value}`"
        ></total-dre>
      </div>
      <div class="modal-footer">
        <div>
          <a @click="closeModal" class="btn btn-secondary">Fechar</a>
        </div>
    </div>
  </modal>
</template>

<script>
import itemLucro from "./itemLucroLiquido.vue";
import totalLucroLiquido from "./totalLucroLiquido.vue";
import modal from "@/components/modal/modalDefault.vue";
import itensDre from "./itensDre.vue";
import totalDre from "./totalDre.vue";
import totalDEF from "./totalDEF.vue";
import { HalfCircleSpinner } from "epic-spinners";

export default {
  components: {
    modal,
    itensDre,
    totalDre,
    HalfCircleSpinner,
    totalDEF,
    itemLucro,
    totalLucroLiquido
  },
  props:["propsModal", "itemDre", "dataRE"],
 
  data() {
    return {
      modal_md: 'modal_lg',
      allInformations: [],
      valueDEF: "",
      valueMes:"",
      sizeWindow: 0,
    }
  },
   watch: {
    propsModal: function() {
      this.valueDEF = this.propsModal.title
      this.valueMes = this.propsModal.month

    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
   
  },
  mounted(){
    this.sizeWindow = $(window).width();
  }
   
}
</script>


<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
#valorValor{
  padding-right: 10%;
}
#valorTotal{
  display: flex;
  float: right;
  margin-left: 43%;
}
#valorEfetivo{
  display: flex;
  float: right;
  padding-left: 23%;
}
.totalDre{
  display: fixed;
}

.fixedMounted{
  max-height: 300px;
  overflow: auto;
}
 
.modalBody{
  max-height: 400px;
  overflow: auto;
}
    .categoria{
        font-size: 16px;
        width: 10%;
        padding-right: 83%;
        padding-left: 20px;
    }
    .paHead{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .valor{
      font-size: 16px;
      float: right;
    }
    .btn-secondary{
      color: rgb(55, 97, 169);
      background-color: #FFFF;
      border: 2px rgb(55, 97, 169) solid;
    }
    .btn-secondary:hover{
      background-color: rgb(55, 97, 169);
      color: #FFFF;
      border-color:  rgb(55, 97, 169);
      transition: 0.7s;
      
    }
    .modal-footer{
      border: none
    }
    p{
      margin-bottom: 5px!important;
    }
    .totalDre{
      padding-top: 20px;
    }

    
  
</style>
