<template>
  <div class="colunaContainer col-md-3">
    <div class="box d-flex w-100 align-items-center px-2 mt-3" id="boxOne">
      <P class="mb-0 lucro"><strong>RECEITA BRUTA</strong></P>
      <a @click="$emit('showModalDetails', 'Receita Bruta', 'Total', year, sumTypeDre(item.map(s => s.grossProfit)), null, 'greenPlusIcon.svg')" id="newsPaper"> <img src="@/assets/image/icones/dreIcon.svg"></a>
    </div>
    <div class="box d-flex w-100 align-items-center px-2 mt-3 despesa" id="boxTwo">
      <P class="mb-0 lucro"><strong>DESPESA</strong></P>
      <a @click="$emit('showModalDetails', 'Despesa', 'Total', year, sumTypeDre(item.map(s => s.expense)), null, 'menorRedIcon.svg' )" id="newsPaper2"> <img src="@/assets/image/icones/dreIcon.svg"></a>
    </div>
    <div class="box d-flex w-100 align-items-center px-2 mt-3" id="boxTree">
      <P class="mb-0 lucro"><strong>LUCRO LÍQUIDO</strong></P>
      <a @click="$emit('showModalDetails', 'Lucro Líquido', 'Total', year, sumTypeDre(item.map(s => s.netProfit)), null, 'blueEqualIcon.svg')" id="newsPaper"> <img src="@/assets/image/icones/dreIcon.svg"></a>
    </div>
    
  </div>
</template>

<script>


export default {
  props:["item", "year", "dataField"],
  data(){
    return{
      diferModal: {}
    }   
  },
  methods:{    

    sumTypeDre(arr){
      var somado = 0;
      arr.forEach(element => {
        somado += parseFloat(element.replace("R$", "").replace(".","").replace(",", "."));
      });
      return `R$ ${this.valueConvertBR(somado)}`;
    }
  }
}
</script>

<style scoped>
.box {
  height: 60px;
  color: rgb(65, 64, 64);
  border-radius: 8px; 
  max-width: 200px;
}
p{
  min-width: 85%;
}
.box p{
  font-size: 18px; 
}
#boxOne{
  background-color: rgb(135, 254, 143);
  margin-top: 23px!important;
}
#boxTwo{
  background-color: rgb(253, 195, 195);
  margin-top: 8px!important;
}
#boxTree{
  background-color: rgb(192, 225, 248);
  margin-top: 8px!important;
}
.colunaContainer{
  background-color: #FFFF;
  margin-top: 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 102%!important;
  min-width: 102%!important;
  position: absolute;
  min-height: 283px!important;
}
#newsPaper{
  cursor: pointer;
}
#newsPaper2{
  cursor: pointer;
}
i{
  cursor: pointer
}
</style>