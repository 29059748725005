<template>
    <div>
        <div>
            <div class="boxIndicator d-flex w-100 align-items-center px-2 mt-3 despesa" id="boxTwo">
                <P class="mb-0 lucro"><strong>DESPESA</strong></P>
                <a @click="$emit('showModalDetails', 'Despesa', 'Total', year, sumTypeDre(item.map(s => s.expense)), null, 'menorRedIcon.svg' )" id="newsPaper2"> <img src="@/assets/image/icones/dreIcon.svg"></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ["item", "year", "dataField"],
  methods:{    
    sumTypeDre(arr){
        var somado = 0;
        arr.forEach(element => {
            somado += parseFloat(element.replace("R$", "").replace(".","").replace(",", "."));
        });
        return `R$ ${this.valueConvertBR(somado)}`;
    }
}
}
</script>

<style scoped>

p{
  min-width: 85%;
}
.box p{
  font-size: 18px; 
}
#boxTwo{
  background-color: rgb(253, 195, 195);
  margin-top: 8px!important;
}
.boxIndicator {
  height: 60px;
  color: rgb(65, 64, 64);
  border-radius: 8px; 
}
</style>