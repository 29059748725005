<template>
    <div>
        <div>
            <div class="boxIndicator d-flex w-100 align-items-center px-2 mt-3" id="boxOne">
                <P class="mb-0 lucro"><strong>RECEITA BRUTA</strong></P>
                <a @click="$emit('showModalDetails', 'Receita Bruta', 'Total', year, sumTypeDre(item.map(s => s.grossProfit)), null, 'greenPlusIcon.svg')" id="newsPaper"> <img src="@/assets/image/icones/dreIcon.svg"></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ["item", "year", "dataField"],
  methods:{    
    sumTypeDre(arr){
        var somado = 0;
        arr.forEach(element => {
            somado += parseFloat(element.replace("R$", "").replace(".","").replace(",", "."));
        });
        return `R$ ${this.valueConvertBR(somado)}`;
    }
}
}
</script>

<style scoped>
.boxIndicator {
  height: 60px;
  color: rgb(65, 64, 64);
  border-radius: 8px; 
}
p{
  min-width: 85%;
}
.box p{
  font-size: 18px; 
}
#boxOne{
  background-color: rgb(135, 254, 143);
  margin-top: 8px!important;
}
</style>