<template>
  <div class="grafico px-4 my-5">
    <div class="row h-100 align-items-center">
      <div class="col-md-2">
        <p>Gráficos</p>
        <ul>
          <li
            v-for="(item, index) in menu"
            :key="index"
            class="mb-1"
            :class="item.link == cp.link ? 'active' : ''"
            @click.prevent="trocaCp(item)"
          >
            {{ item.nome }}
          </li>
        </ul>
      </div>
      <div class="col-md-10 widht_content">
        <chart :chart-data="datacollection" :styles="myStyles"></chart>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/chart/chart";
export default {
  props: ["grafico"],
  components: {
    Chart
  },
  data() {
    return {
      datacollection: null,
      cp: { nome: " Receita Bruta", link: "bruta", id: 1 },
      menu: [
        { nome: " Receita Bruta ", link: "bruta", id: 1 },
        { nome: "Receita Líquida ", link: "liquida", id: 2 },
        { nome: "Despesas ", link: "despesa", id: 3 },
        { nome: "Meta", link: "meta", id: 4 },
        { nome: "Média/Dia ", link: "media", id: 5 },
        { nome: "Ticket Médio ", link: "ticket", id: 6 }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.fillData(this.cp);
    });
  },
  methods: {
    fillData(cp) {
      var label = [];
      var dataValue = [];

      this.grafico.forEach(element => {
        if (element.id == this.cp.id) {
          element.item.forEach((item, index) => {
            label.push(`${item.name}`);

            dataValue.push(item.value);
          });
        }
      });

      this.datacollection = {
        labels: label,
        datasets: [
          {
            label: this.cp.nome,
            borderColor: "rgba(255,112,142,1)",
            backgroundColor: "rgba(255,112,142,0.5)",
            borderWidth: "2",
            barPercentage: 0.6,
            data: dataValue
          }
        ]
      };
    },
    trocaCp(item) {
      this.cp = item;
      this.$nextTick(() => {
        this.fillData(this.cp);
      });
    }
  },
  computed: {
    myStyles() {
      return {
        height: "200px",
        width: "100%",
        position: "relative"
      };
    }
  }
};
</script>

<style scoped>
p {
  font-size: 14px;
  font-weight: 700;
  color: #e3e3e3;
}
li {
  font-size: 14px;
  color: #868686;
  cursor: pointer;
}
.active {
  font-weight: 700;
  color: #2474b2;
}
.grafico {
  background-color: #ffffff;
  height: 250px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
ul {
  list-style: none;
  padding: 0;
}
@media screen and (max-width:767px){
	.grafico{
    height: 420px;
    margin-top: 0!important;
  }
}
</style>
